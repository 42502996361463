<template>
    <a :href="`https://api.whatsapp.com/send?phone=${phone}&text=${text}`"
        class="float" target="_blank">
        <font-awesome-icon icon="fa-brands fa-whatsapp" class="my-float" />
    </a>
</template>

<style scoped>
.float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    z-index: 100;
}

.my-float {
    margin-top: 16px;
}
</style>

<script>
export default {
    data(){
        return {
            phone: "558432214142",
            text: encodeURI("Olá, tudo bem?")
        }
    }
}
</script>
