<template>
    <div class="container">
        <WhatsappButton />
        <div class="row">
            <footer>
                <div class="bg-blue-dark pr-5 pl-5">
                    <b-row>
                        <b-col class="text-center" cols="12">
                            <a href="https://www.instagram.com/acao.camisetas/" target="_new">
                                <h1 class="mt-5 mb-4 text-white text-lowercase">
                                    <span class="text-warning">siga </span>
                                    @acao.camisetas
                                </h1>
                            </a>
                        </b-col>
                    </b-row>
                    <b-row class="row align-items-center bg-blue-dark text-white p-4">
                        <b-col class="mx-auto text-center" md="4">
                            <a href="mailto:atendimento@acaocamisetas.com.br">
                                <img class="pb-2" :src="svgEmail" />
                                <p class="text-break">
                                    atendimento@acaocamisetas.com.br
                                </p>
                            </a>
                        </b-col>
                        <b-col class="mx-auto text-center" md="4">
                            <img class="pb-2" :src="svgPhone" />
                            <p><a href="tel:558432214142">+55 84 3221.4142</a> | <a
                                    href="tel:5584996220589">99622.0589</a></p>
                        </b-col>
                        <b-col class="mx-auto text-center" md="4">
                            <a href="https://www.google.com/maps/dir//acao+camisetas" target="_blank">
                                <img class="pb-2" :src="svgAddress" />
                                <p>Av. Deodoro da Fonseca, 420 - Cidade Alta - Natal/RN</p>
                            </a>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="mt-4 mb-4 text-center" sm="6" md="4">
                            <h4>Menu</h4>
                            <div class="title-bottom-border mx-auto"></div>
                            <ul>
                                <li>
                                    <router-link to="/">Home</router-link>
                                </li>
                                <li>
                                    <router-link to="/sobre">Sobre</router-link>
                                </li>
                                <li>
                                    <router-link to="/produtos/esportivo">Produtos</router-link>
                                </li>
                                <li>
                                    <router-link to="/servicos">Serviços</router-link>
                                </li>
                                <li>
                                    <router-link to="/contato">Contato</router-link>
                                </li>
                            </ul>
                        </b-col>
                        <b-col class="mt-4 mb-4 text-center" sm="6" md="4">
                            <h4>Produtos</h4>
                            <div class="title-bottom-border mx-auto"></div>
                            <ul>
                                <li>
                                    <a href="/produtos/esportivo">Esportivo</a>
                                </li>
                                <li>
                                    <a href="/produtos/cursos">Cursos</a>
                                </li>
                                <li>
                                    <a href="/produtos/fardamento">Fardamento</a>
                                </li>
                                <li>
                                    <a href="/produtos/religioso">Religioso</a>
                                </li>
                                <li>
                                    <a href="/produtos/abadas">Abadás</a>
                                </li>
                                <li>
                                    <a href="/produtos/outros">Outros</a>
                                </li>
                            </ul>
                        </b-col>
                        <b-col class="mt-4 mb-4 text-center" sm="6" md="4">
                            <h4>Serviços</h4>
                            <div class="title-bottom-border mx-auto"></div>
                            <ul>
                                <li>
                                    <router-link to="/servicos?name=sublimacao">Sublimação</router-link>
                                </li>
                                <li>
                                    <router-link to="/servicos?name=serigrafia">Serigrafia</router-link>
                                </li>
                                <li>
                                    <router-link to="/servicos?name=corte-a-lazer">Corte a lazer</router-link>
                                </li>
                                <li>
                                    <router-link to="/servicos?name=bordado">Bordado</router-link>
                                </li>
                            </ul>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-white text-center" cols="12">
                            <p class="pb-2 pt-4">
                                2021 - © Ação Camisetas - Todos os direitos reservados
                            </p>
                        </b-col>
                    </b-row>
                </div>
            </footer>
        </div>
    </div>
</template>

<style scoped>
a {
    color: white;
}

ul {
    margin: 20px 0px;
    padding-left: 0px;
}

li {
    list-style-type: none;
    margin-bottom: 5px;
}

h1 {
    font-size: 24px;
    font-weight: 700;
}

h4 {
    color: white;
    font-weight: 500;
}

.title-bottom-border {
    background-color: #ffd30f;
    width: 20%;
    height: 3px;
}
</style>

<script>
import EmailIcon from "@/images/icons/email-icon.svg"
import PhoneIcon from "@/images/icons/phone-icon.svg"
import AddressIcon from "@/images/icons/address-icon.svg"
import WhatsappButton from "./WhatsappButton"

export default {
    data() {
        return {
            svgEmail: EmailIcon,
            svgPhone: PhoneIcon,
            svgAddress: AddressIcon
        }
    },
    components: {
        WhatsappButton
    }
}
</script>
