<template>
  <b-container class="content-header" :class="!title ? 'no-text bg-secondary' : ''" :style="`background-image: url(${background})`">
    <b-row>
      <b-col cols="12">
        <h1 class="text-center">{{ title }}</h1>
        <div class="mx-auto border-warning"></div>
      </b-col>
    </b-row>
  </b-container>
</template>

<style scoped>
.content-header {
  background-color: grey;
  background-size: cover;
  background-position: 50%;
  padding: 60px 0;
}
.no-text {
  min-height: 290px
}

h1 {
  color: white;
  font-size: 23px;
  font-weight: 700;
  text-transform: uppercase;
}
</style>

<script>
export default {
  props: {
    title: {
      required: false,
    },
    background: {
      required: true,
    },
  },
  data() {
    return {
      test: "",
    };
  },
};
</script>
