<template>
  <div>
    <header-component />

    <title-component :title="title" :background="background" />

    <b-container class="content-page rounded">
      <b-row>
        <b-col class="mt-5 mb-5 text-center" md="12">
          <p>Oops...</p>
          <h1>404 - Página não encontrada</h1>
          <p>Página não encontrada, porfavor verifique o endereço digitado.</p>
          <a href="/">Voltar a página inicial</a>
        </b-col>
      </b-row>
    </b-container>

    <footer-component />
  </div>
</template>

<style scoped>
h2 {
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}
.bg-slide {
  min-height: 250px;
  background-size: cover;
  background-position: 50%;
}
</style>

<script>
import Background from "@/images/backgrounds/header-page.png";

import HeaderComponent from "@/components/Header";
import FooterComponent from "@/components/Footer";
import TitleComponent from "@/components/Title";
export default {
  data() {
    return {
      title: "Página não encontrada",
      background: Background,
    };
  },
  components: {
    HeaderComponent,
    FooterComponent,
    TitleComponent,
  },
};
</script>
